import styled, { Box } from "@xstyled/styled-components";

import BasicLink from "../../../common/BasicLink/basic-link";

const S = styled(Box)`
    border-radius: 8;
    padding: 4;
    box-shadow: regular;
    display: flex;
    flex-direction: column;
`;

S.title = styled.h2`
    font-size: 4;
    margin-bottom: 3;
    font-weight: bold;
    font-family: "Josefin Sans", sans-serif;
`;
S.content = styled.div`
    font-size: 3;
    margin-bottom: 3;
`;

S.link = styled(BasicLink)`
    align-self: flex-end;
`;

export default S;
