import { Box } from "@xstyled/styled-components";
import React from "react";

import Container from "../components/layout/Container/container";
import Layout from "../components/layout/Main/main";
import QuestionsCard from "../components/pages/faq/QuestionsCard/questions-card";
import SEO from "../components/seo";
import Headline from "../components/typography/Headline/headline";

const FAQ = () => (
    <Layout>
        <SEO
            title="FAQ"
            description="You have questions, Virginia has the answers"
        />
        <Container>
            <Box my={5}>
                <Headline textAlign="center">FAQ</Headline>
            </Box>
            <Box px={0}>
                <Box row mx={{ xs: 0, sm: -3 }} mb={5}>
                    <Box
                        col={{ xs: 1, md: 1 / 2 }}
                        mb={4}
                        px={{ xs: 0, sm: 3 }}
                    >
                        <QuestionsCard
                            title="How do I reserve the tour?"
                            link={{ url: "/contact", label: "Book this tour" }}
                        >
                            <p>
                                On each tour page you will find: “Book this
                                tour”. Please fill in the Tour Request form and
                                submit.
                            </p>
                        </QuestionsCard>
                    </Box>
                    <Box
                        col={{ xs: 1, md: 1 / 2 }}
                        mb={4}
                        px={{ xs: 0, sm: 3 }}
                    >
                        <QuestionsCard title="What form of payment do you accept and how do the tours work?">
                            <p>
                                I accept credit card payments via{" "}
                                <strong>PayPal</strong>. After you book the
                                tour, I shall send a confirmation email with my
                                contact information and the place to meet. All
                                tours are on foot or using public transport
                                unless otherwise arranged.
                            </p>
                        </QuestionsCard>
                    </Box>
                    <Box
                        col={{ xs: 1, md: 1 / 2 }}
                        mb={4}
                        px={{ xs: 0, sm: 3 }}
                    >
                        <QuestionsCard title="What is the cancellation policy?">
                            <p>
                                If I cancel the tour, you will be fully
                                refunded. If you cancel the tour more than 14
                                days prior to the date of the tour I shall
                                refund 50% of the total rate. If you cancel the
                                tour within <strong>two weeks</strong> (14 days)
                                of the tour date there will be no refund.
                                However, I will make every effort to reschedule
                                the tour for a later date, depending on my
                                availability. Rescheduling the tour cannot be
                                guaranteed, so I strongly advise that you have
                                travel insurance that covers the possibility of
                                cancellation of the tour.
                            </p>
                        </QuestionsCard>
                    </Box>
                    <Box
                        col={{ xs: 1, md: 1 / 2 }}
                        mb={4}
                        px={{ xs: 0, sm: 3 }}
                    >
                        <QuestionsCard
                            title="Are the tours appropriate for children?"
                            link={{ url: "/tours", label: "See all tours" }}
                        >
                            <p>
                                The tours are designed for adults. Nevertheless
                                all children who have taken part in my tours
                                have seemed delighted. Why ? I am passionate
                                about Paris, passionate about transmitting my
                                love and knowledge of this incredible city. My
                                tours are not simple monologues. I involve my
                                customers in the tours by asking/answering all
                                questions. I adapt to each person’s interest. I
                                give life to history and make the characters I
                                am talking about alive. I love challenges and
                                children are wonderful in this respect. They
                                have imagination and most of the time their
                                curiosity is unlimited.
                            </p>
                        </QuestionsCard>
                    </Box>
                    <Box
                        col={{ xs: 1, md: 1 / 2 }}
                        mb={4}
                        px={{ xs: 0, sm: 3 }}
                    >
                        <QuestionsCard title="What if the weather is bad?">
                            <p>
                                The tours take place rain or shine. Nevertheless
                                (for our mutual comfort) I do my best to adapt
                                (such as stopping for a drink/toilets). Please
                                be sure to have clothing appropriate for the
                                expected weather conditions (coat, comfortable
                                shoes, umbrella, etc)
                            </p>
                        </QuestionsCard>
                    </Box>
                    <Box
                        col={{ xs: 1, md: 1 / 2 }}
                        mb={4}
                        px={{ xs: 0, sm: 3 }}
                    >
                        <QuestionsCard title="What if I am late for the tour or I do not show up ?">
                            <p>
                                If you do not arrive within{" "}
                                <strong>20 minutes</strong>, I must treat this
                                as a cancellation (no refund). In some cases,
                                depending upon availability, I may be able to
                                reschedule your tour. If you get lost on your
                                way to meeting me, just give me a call or text
                                my cell phone.
                            </p>
                        </QuestionsCard>
                    </Box>
                    <Box
                        col={{ xs: 1, md: 1 / 2 }}
                        mb={4}
                        px={{ xs: 0, sm: 3 }}
                    >
                        <QuestionsCard title="What if I need to change my date/time?">
                            <p>
                                I can adjust your date or time at no charge, if
                                I am available.
                            </p>
                        </QuestionsCard>
                    </Box>
                    <Box
                        col={{ xs: 1, md: 1 / 2 }}
                        mb={4}
                        px={{ xs: 0, sm: 3 }}
                    >
                        <QuestionsCard title="What if I want to add people on my tour?">
                            <p>
                                You simply pay the difference in cash when we
                                meet.
                            </p>
                        </QuestionsCard>
                    </Box>
                    <Box
                        col={{ xs: 1, md: 1 / 2 }}
                        mb={4}
                        px={{ xs: 0, sm: 3 }}
                    >
                        <QuestionsCard title="Do you accept tips?">
                            <p>
                                The cost of the tour is including the taxes, but
                                if you consider that the service has been
                                particularly helpful and your guide generous
                                with her time and energy she would be happy to
                                accept tips.
                            </p>
                        </QuestionsCard>
                    </Box>
                    <Box
                        col={{ xs: 1, md: 1 / 2 }}
                        mb={4}
                        px={{ xs: 0, sm: 3 }}
                    >
                        <QuestionsCard title="How can I help to spread the word if I really enjoyed my PARIS INSIGHT tour?">
                            <p>
                                The best compliment is that you{" "}
                                <strong>leave a review</strong> on my website,
                                on TripAdvisor and/or on social medias you use.
                            </p>
                        </QuestionsCard>
                    </Box>
                    <Box col={1} mb={4} px={{ xs: 0, sm: 3 }}>
                        <QuestionsCard
                            title="What if I have other questions?"
                            link={{ url: "/contact", label: "Contact me" }}
                        >
                            <p>
                                Please contact me via email or telephone. I
                                shall respond to your questions within 48 hours.
                            </p>
                        </QuestionsCard>
                    </Box>
                </Box>
            </Box>
        </Container>
    </Layout>
);

export default FAQ;
