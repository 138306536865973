import PropTypes from "prop-types";
import React from "react";

import S from "./questions-card.style";

const QuestionsCard = ({ title, link, children }) => (
    <S>
        <S.title>{title}</S.title>
        <S.content>{children}</S.content>
        {link && <S.link to={link.url}>{link.label}</S.link>}
    </S>
);

QuestionsCard.propTypes = {
    title: PropTypes.string,
    link: PropTypes.shape({
        url: PropTypes.string,
        label: PropTypes.string,
    }),
    children: PropTypes.node.isRequired,
};

export default QuestionsCard;
